* {
  box-sizing: border-box;
  transition: all 0.3s;
}
body {
  margin: 0;
  font-family: $main-font;
  width: 100%;
  height: 100%;
  position: relative;
}
p {
  font-weight: $font-weight-p;
  line-height: 25px;
  font-size: $font-size-p;
}
.bold {
  font-weight: $font-weight-bold;
}
