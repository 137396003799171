.club {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 50px;
  position: relative;
  flex-direction: column;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    flex-direction: row;
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) {
  }

  .victory {
    position: static;
    width: 100%;
    height: auto;

    @media (min-width: 992px) {
      position: absolute;
      top: 15%;
      left: -3%;
      width: 60%;
      height: auto;
      z-index: 1;
    }
  }
  .club-content {
    position: relative;
    background-color: $fifth-color;
    font-family: $font-content;
    color: $third-color;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 35px;
    @media (min-width: 992px) {
      width: 45%;
    }
    h2 {
      font-family: $main-font;
      background-color: transparent;
      margin: 0;
      padding-left: 10px;
      padding-top: 0;
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    p {
      margin-bottom: 10px;
    }
    .last-p {
      margin-bottom: 20px;
    }
  }
}
