@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,300;0,400;0,700;1,500&display=swap");

//colors
$first-color: #f2ce4f;
$second-color: #1a2c4d;
$third-color: white;
$fourth-color: #2e3f5d;
$fifth-color: #384765;
$tc-opacity: #3c4b68;
$sc-opacity: rgba(26, 44, 77, 0.9);

//font
$main-font: "Oswald", sans-serif;
$font-content: "Alegreya Sans", sans-serif;

//font-size
$font-size-menu: 20px;
$font-size-title: 22px;
$font-size-btitle: 25px;
$font-size-p: 18px;

//font-weight
$font-weight-p: 300;
$font-weight-title: 400;
$font-weight-bold: 700;
