.img-contact {
  width: 40%;
  height: auto;
  @media (max-width: 992px) {
    display: none;
  }
  img {
    width: 100%;
  }
}

.flex-contact {
  align-items: flex-start;
  justify-content: flex-start;
  form {
    flex-grow: 1;
    background-color: $tc-opacity;
    color: $third-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    margin-left: 30px;
    @media (max-width: 992px) {
      width: 100%;
      margin-left: 0;
    }
    .title-content {
      background-color: transparent;
      padding: 0;
      padding-left: 12px;
      margin: 0;
      margin-bottom: 30px;
      &::before {
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    label {
      margin: 10px 0;
    }
    input,
    textarea {
      font-size: $font-size-p;
      font-weight: $font-weight-title;
      font-family: $font-content;
      color: $third-color;
      padding: 5px 5px;
      width: 100%;
      border: none;
      background-color: rgba(255, 255, 255, 0.25);
      &:focus {
        outline: none;
      }
    }
    button {
      cursor: pointer;
      margin: 15px 0 15px 1px;
      border: none;
      padding: 7px 10px;
      background-color: $first-color;
      background-size: contain;
      color: $second-color;
      font-size: 15px;
      text-transform: uppercase;
      text-decoration: none;
      display: inline-flex;
      position: relative;
      font-family: $main-font;
      font-size: $font-size-menu;
      z-index: 0;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $second-color;
        transition: all 0.3s;
        z-index: -1;
      }
      &:hover {
        z-index: 0;
        color: $first-color;
        &::before {
          width: 100%;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
}
.text-content {
  h2 {
    font-family: $main-font;
    @media (min-width: 992px) {
      margin-left: 0px;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  .infos-contact {
    margin-top: 15px;
    background-color: $fifth-color;
    padding: 15px;
    text-align: center;
    a {
      color: $first-color;
      text-decoration: none;
    }
  }
  .title-category {
    margin-top: 0;
  }
}
