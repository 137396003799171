ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
  li {
    &:first-child {
      a {
        &::before {
          width: 0%;
        }

        &:hover {
          color: $second-color;
          &::before {
            width: 100%;
          }
        }
      }
    }
    a {
      text-decoration: none;
      color: $first-color;
      text-transform: uppercase;
      font-size: $font-size-menu;
      padding: 15px 20px;
      position: relative;
      z-index: 9;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: $first-color;
        transition: all 0.3s;
        z-index: -1;
      }
      &:hover {
        color: $second-color;
        &::before {
          width: 100%;
        }
      }
    }
    display: flex;
    align-items: center;
  }
}
header {
  z-index: 9;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  background-color: $third-color;
  &.d-none {
    display: none;
  }
  p {
    color: $second-color;
  }
  .container-menu-fixe {
    padding: 15px 15px;
    position: relative;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    @media (min-width: 576px) {
      justify-content: space-between;
      width: 540px;
      padding: 15px 0;
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      width: 720px;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      width: 960px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      width: 1140px;
    }

    // XX-Large devices (larger desktops, 1400px and up)
    @media (min-width: 1400px) {
      width: 1320px;
    }
    .logodark {
      a {
        display: block;
        height: 74px;
        width: auto;
        position: relative;
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    .d-flex-menu {
      @media (max-width: 576px) {
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0px;
        top: 0;
        display: flex;
        // align-items: center;
        justify-content: center;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      justify-content: flex-end;

      & > * {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .menuconnexion-menuFixe {
        @media (max-width: 576px) {
          position: absolute;
          left: 15px;
          margin-bottom: 0;
        }
        & > * {
          margin-left: 10px;
        }

        display: flex;
        align-items: center;
        .connexion {
          margin-right: 0;
        }
        .facebook {
          border-radius: 100%;
          border: 3px solid $first-color;
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          @media (max-width: 576px) {
            margin-left: 0;
          }
          &:hover {
            background-color: $second-color;
            border-color: $second-color;
          }
          svg {
            height: 15px;
            fill: $first-color;
          }
        }
        .connexion {
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 576px) {
            display: none;
          }
          a {
            padding: 7px 10px;
            font-size: 15px;
          }
        }
      }
      .menuaccueil-menuFixe {
        .menu-burger {
          display: none;
          padding-right: 15px;
          .burger-line {
            transform-origin: right;
            height: 3px;
            width: 31px;
            margin: 4px 0;
            background-color: $second-color;
            transition: all 0.3s;
          }
          @media (max-width: 576px) {
            display: flex;
            flex-direction: column;
            cursor: pointer;
          }
        }
        .container-menu {
          @media (max-width: 576px) {
            justify-content: flex-end;
            left: 100%;
            display: flex;
            width: 100%;
            position: absolute;
            top: 100px;
            background-color: $third-color;
            transition: all 0.5s;
            padding: 25px 15px 0px 15px;
            ul {
              display: flex;
              flex-direction: column;
              li {
                padding: 5px 0;
                justify-content: flex-end;
                a {
                  &::before {
                    width: 0;
                  }
                }
              }
            }
            &.d-flex {
              left: 0;
              & + .menu-burger {
                .line2 {
                  background-color: transparent;
                }
                .line1 {
                  transform: rotate(-45deg);
                }
                .line3 {
                  transform: rotate(45deg);
                }
              }
            }
          }
        }
        nav {
          ul {
            li {
              a {
                &.active {
                  &::before {
                    width: 100%;
                    background-color: $first-color;
                  }
                }
                color: $second-color;
                padding: 7px 12px;
              }
            }
          }
        }
      }
    }
  }
}
